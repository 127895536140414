import fetch from '../utils/fetch'

// 医保用户列表
export function userList (params) {
  return fetch({
    url: `/yibao/user/list`,
    method: 'get',
    params
  })
}

// 医保用户查看
export function getUser (id) {
  return fetch({
    url: `/yibao/user/` + id,
    method: 'get'
  })
}

// 医保用户添加
export function addUser (query) {
  return fetch({
    url: `/yibao/user`,
    method: 'post',
    params: query
  })
}

// 医保用户编辑
export function editUser (id, query) {
  return fetch({
    url: `/yibao/user/` + id,
    method: 'put',
    params: query
  })
}

// 医保用户删除
export function delUser (id) {
  return fetch({
    url: `/yibao/user/` + id,
    method: 'delete'
  })
}

// 医保地区列表
export function areaList (params) {
  return fetch({
    url: `/yibao/area/list`,
    method: 'get',
    params
  })
}

// 医保地区添加
export function addAreaList (params) {
  return fetch({
    url: `/yibao/area`,
    method: 'post',
    params
  })
}

// 医保地区编辑
export function editAreaList (id, query) {
  return fetch({
    url: `/yibao/area/` + id,
    method: 'put',
    params: query
  })
}

// 医保地区删除
export function delAreaList (id) {
  return fetch({
    url: `/yibao/area/` + id,
    method: 'delete'
  })
}

// 复制医保目录
export function copyAreaGoods (query) {
  return fetch({
    url: `/yibao/items/copyItem`,
    method: 'post',
    params: query
  })
}

// 医保商品列表
export function goodsList (params) {
  return fetch({
    url: `/yibao/items/list`,
    method: 'get',
    params
  })
}

// 医保商品查看
export function getGoodsInfo (id) {
  return fetch({
    url: `/yibao/items/` + id,
    method: 'get'
  })
}

// 医保商品编辑
export function editGoodsInfo (id, query) {
  return fetch({
    url: `/yibao/items/` + id,
    method: 'put',
    params: query
  })
}

// 医保商品添加
export function addGoodsInfo (params) {
  return fetch({
    url: `/yibao/items/add`,
    method: 'post',
    params
  })
}

// 医保商品删除
export function delGoodsList (id) {
  return fetch({
    url: `/yibao/items/` + id,
    method: 'delete'
  })
}

// 医保订单列表
export function ordersList (params) {
  return fetch({
    url: `/yibao/order/list`,
    method: 'get',
    params
  })
}

// 医保订单详情
export function ordersDetail (id) {
  return fetch({
    url: `/yibao/order/info/` + id,
    method: 'get'
  })
}

// 手动医保平台订单撤销
export function outOrderCancle (distributor_id, order_id) {
  return fetch({
    url: `/yibao/order/outOrderCancle/${distributor_id}/${order_id}`,
    method: 'get'
  })
}

// 医保订单退款
export function outOrderRefund (distributor_id, order_id) {
  return fetch({
    url: `/yibao/order/outOrderRefund/${distributor_id}/${order_id}`,
    method: 'get'
  })
}

// 医保订单退款查询
export function refundCashQuery (distributor_id, order_id) {
  return fetch({
    url: `/yibao/order/refundCashQuery/${distributor_id}/${order_id}`,
    method: 'get'
  })
}

// 医保平台订单查询
export function outOrderQuery (distributor_id, order_id) {
  return fetch({
    url: `/yibao/order/outOrderQuery/${distributor_id}/${order_id}`,
    method: 'get'
  })
}

// 下载医保账单
export function outOrderBill (distributor_id, params) {
  return fetch({
    url: `/yibao/order/outOrderBill/${distributor_id}`,
    method: 'get',
    params
  })
}
